import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "instructional-text"
    }}>{`Instructional Text`}</h1>
    <p>{`When writing instructions for users (often the first sentence at the top of a page), try to be as
brief yet helpful as possible. Use sentence case for instructional text.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`View the calibration event status for supervisory organizations that report to you.`}</li>
        <li parentName="ul">{`Completes all events that are in-progress in that user's organization hierarchy.`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Calibration Status page shows only Supervisory Organizations that report to you.`}</li>
        <li parentName="ul">{`Enables you to complete in-progress calibration events for all subordinate organizations in the
hierarchy.`}</li>
      </ul>
    </Dont>
    <h2 {...{
      "id": "hover-text"
    }}>{`Hover Text`}</h2>
    <p>{`Instructional text may also include hover text that gives users more information about the UI
element in focus. Using hover text paired with an icon can be a good alternative to including a long
label before a UI element. Follow the same guidelines for hover text that you would for other
instructional text.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Select up to 5 dimensions to include in the headcount plan.`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Including dimensions in your headcount plan enables you to specify what type of workers you need
and where you need them. The more dimensions you include, the better you will be able to analyze
and report on what type of workforce you need, when you need it, and whether or not you are on
track to achieve it. You can include up to 5 dimensions in 1 headcount plan.`}</li>
      </ul>
    </Dont>
    <p>{`See also: `}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/placeholder-text"
      }}>{`Placeholder Text`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      